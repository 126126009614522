<template>
    <finish />
</template>

<script>
// @ is an alias to /src
import finish from "@/components/supplier_apply/finish.vue";
export default {
  name: "sup_finish",
  components: {
    finish,
  }
};
</script>